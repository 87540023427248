import Layout from "@/layout/Container.vue"

export const commonRouter = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: "吾有空-登录",
      content: {
				keywords: '吾有空',
				description: '吾有空。'
			}
    },
    component: () => import("@/views/login")
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: {
  //     title: "吾有空-首页",
  //     content: {
	// 			keywords: '吾有空',
	// 			description: '吾有空。'
	// 		}
  //   },
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/home',
  //       name: 'HomeIndex',
  //       component: () => import("@/views/home/index.vue")
  //     }
  //   ]
  // },
  {
    path: '/order',
    name: 'Order',
    meta: {
      title: "吾有空-我的工单",
      content: {
				keywords: '吾有空',
				description: '吾有空。'
			}
    },
    component: Layout,
    children: [
      {
        path: '/order',
        name: 'OrderIndex',
        component: () => import("@/views/order/index.vue")
      }
    ]
  },
  {
    path: '/created',
    name: 'Created',
    meta: {
      title: "吾有空-发布需求",
      content: {
				keywords: '吾有空',
				description: '吾有空。'
			}
    },
    component: Layout,
    children: [
      {
        path: '/created',
        name: 'CreatedIndex',
        component: () => import("@/views/created/index.vue")
      }
    ]
  },
  
  {
    path: '*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import("@/views/404")
  }
]



