<template>
  <div class="layout__wrap">
    <div class="layout__main">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LayoutContainer',
  data() {
    return {
    }
  },
  methods: {
    logout() {
      this.$confirm("请确认是否退出登录？", "提示", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            clear(); // 删除token
            window.location.reload();
            done()
          } else {
            done()
          }
        }
      })
    },
   
    
    
  }
}
</script>

<style scoped lang="scss">
.layout__wrap {
  width: 100%;
  height: 100vh;
  // overflow: hidden;
  
  .header__wrap {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $theme !important;
    border-bottom: solid 1px #eee;
    // background-image: linear-gradient(to right, $theme, #0bCFFF);
    .logo__wrap {
      display: flex;
      align-items: center;
      .logo {
        display: block;
        width:180px;
        // height: 32px;
      }
      .logo_txt {
        color: #5a5a5a;
        font-weight: bold;
        margin-left: 40px;
      }
      .J_menutoggle {
        color: #fff;
        margin-left: 20px;
      }
    }
    .avatar__wrap {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 14px;
      cursor: pointer;

      .title {
        width: 100%;
        text-align: center;;
      }
      .logout {
        width: 120px;
        margin-right: 20px;
      }
      
      ::v-deep .el-popover {
        min-width: 80px;
      }
    }
  }
  .layout__main {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #f5f5f5;

    .page-header__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #FFFFFF;
      color: #595959;

      .title {
        height: 58px;
        line-height: 58px;
        
      }
    }

    &>div {
      flex: 1;
      min-height: 0;
      // overflow-y: scroll;
    }

  }
}
.logoBg {
  padding: 0 20px;
  background: rgb(255, 255, 255);
  width: 210px;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #eee;
}
</style>
